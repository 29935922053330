import React, { ReactElement } from 'react'

import {
  startProjectSection,
  whiteButton,
  wrapper,
  copyrightContainer,
  root,
  columnHeader,
  columnBig,
  contactsContainer,
  contactsStatic
} from './AppFooter.module.scss'
import { graphql, Link, useStaticQuery } from 'gatsby';
import SVG from 'react-inlinesvg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '~components/Button';
import * as styles from '~components/Header/Header.module.scss';
import LinkButton from '~components/LinkButton';
import { useLocation } from '@reach/router';
import Logo from '~components/Logo';

export default function Footer(): ReactElement {
  const { pathname } = useLocation();
  const data = useStaticQuery(query);
  const promo = data.allContentfulPromoBox?.nodes[0];
  const links = data.allContentfulFooterLinks?.nodes;
  const contacts = data.allContentfulContacts?.nodes[0];

  const handleSignUp = () => {}

  return (
    <>
      {!pathname.match('start-project') &&
      <div className={startProjectSection}>
        <div>
          {documentToReactComponents(JSON.parse(promo.desc.raw))}
          <br />
          <Button
            className={whiteButton}
            handleClick={handleSignUp}
          >
            <Link to={`/${promo.link.url ?? ``}`}>{promo.link.title}</Link>
          </Button>
        </div>
      </div>}
      <footer className={root}>
        {/*{pathname.match('start-project') && <div className={`${wrapper} ${contactsContainer}`} />}*/}
        {/*<div className={`${wrapper} ${contactsContainer}`}>*/}
        {/*  <div>*/}
        {/*    <div className={contactsStatic}>Office</div>*/}
        {/*    <div>*/}
        {/*      {documentToReactComponents(JSON.parse(contacts.richAddress.raw))}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <div>{contacts.email}</div>*/}
        {/*    <div>{contacts.phone}</div>*/}
        {/*  </div>*/}
        {/*  {contacts.enabled && (<div>*/}
        {/*    <div className={contactsStatic}>Follow as</div>*/}
        {/*    <div>*/}
        {/*      {contacts.external.map((link: any, index: number) => (*/}
        {/*        <a href={link.url}>*/}
        {/*          <SVG key={index} src={link.icon.file.url} />*/}
        {/*        </a>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*  </div>)}*/}
        {/*</div>*/}
        <div>
          <div>
            <Logo />
          </div>
          <div className={copyrightContainer}>
            {contacts.phone}
            <br />
            {contacts.email}
            <br />
            {documentToReactComponents(JSON.parse(contacts.richAddress.raw))}
            <br />
            © {new Date().getFullYear()} TPU Soft.
            <br />
            All rights reserved
          </div>
          {links.sort((a: any, b: any) => a.order - b.order).map((link: any, index: number) => (
            <div key={index} className={link.isLink ? columnBig : ''}>
              {!link.isLink && <div className={link.isBold ? columnHeader : ''}>{link.title}</div>}
              {link.links.sort((a: any, b: any) => a.order - b.order).map((item: any, index: number) => (
                <Link
                  key={index}
                  to={item.url ?? ``}
                  className={`${styles.links} ${pathname.match(item.url) ? styles.active : ``}`}
                >{item.title}</Link>
              ))}
            </div>
          ))}
        </div>
      </footer>
    </>
  )
}

export const query = graphql`
query allContentfulFooterLinksQuery {
  allContentfulPromoBox {
    nodes {
      link {
        title
        url
      }
      desc {
        raw
      }
    }
  }
  allContentfulFooterLinks {
    nodes {
      order
      title
      isLink
      isBold
      links {
        order
        url
        title
      }
    }
  }
  allContentfulContacts {
    nodes {
      enabled
      address
      richAddress {
        raw
      }
      email
      phone
      external {
        title
        order
        icon {
          file {
            contentType
            url
          }
        }
      }
    }
  }
}
`
