import React, { memo } from 'react'
import LogoImg from '~images/logo.svg';

import { logoLabel, root, logo } from './Logo.module.scss'

function Logo() {
  return (
    <div className={root}>
      <img src={LogoImg} className={logo} />
      {/*<span className={logoLabel}>TPU Soft</span>*/}
    </div>
  )
}

export default memo(Logo)
