import '../base.scss'

import React, { ReactElement } from 'react'

import Footer from '../Footer'
import Header from '../Header'
import SEO from '../Seo'
import { main } from './Layout.module.scss'

type Props = {
  children: ReactElement | (false | null | ReactElement)[]
  article?: unknown
}

const Layout: React.FC<Props> = ({ children, article }) => (
  <>
    <SEO
    // title={article?.title}
    // description={article?.description}
    // image={article?.image?.gatsbyImageData?.images?.fallback?.src}
    />
    <Header />
    <div>
      <main className={main}>{children}</main>
      <Footer />
      {/* TODO: style optout, see https://www.gatsbyjs.com/plugins/gatsby-plugin-google-analytics/#anonymize */}
      {/*<a href="javascript:gaOptout();">Deactivate Google Analytics</a>*/}
    </div>
  </>
)
export default Layout
