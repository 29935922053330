import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { ReactElement, useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useWindowScroll } from 'react-use'

import Button from '~components/Button'
import { authorize } from '~utils/auth'

import Logo from '../Logo'
import * as styles from './Header.module.scss'
import { useLocation, navigate } from '@reach/router';

import HamIcon from '~images/ham.svg'
import CrossIcon from '~images/cross.svg'

const Header = (): ReactElement => {
  const { pathname } = useLocation();
  const data = useStaticQuery(query);
  const links = data.allContentfulHeaderLinks?.nodes;
  const [showOverlay, setShowOverlay] = useState(false);

  const { y } = useWindowScroll()

  const showHideOverlay = () => {
    if (showOverlay) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setShowOverlay(!showOverlay)
  }

  return (
    <header
      className={styles.root}
      style={y < 10 ? { background: 'transparent' } : {}}
    >
      <div className={styles.wrapper}>
        <Link
          to='/'
          style={{
            color: `#FBCDA3`,
            textDecoration: `none`,
          }}
        >
          <Logo />
        </Link>
        {(!isMobile || showOverlay) && (
          <div className={`${styles.buttonsContainer} ${showOverlay ? styles.overlay : ``}`}>
            {links.sort((a: any, b: any) => a.order - b.order).map((link: any, index: number) => {
              if (!link.isButton) return (
                <Link
                  key={index}
                  onClick={() => { document.body.style.overflow = 'unset'; setShowOverlay(false); }}
                  to={link.link.url ?? ``}
                  className={`${styles.links} ${pathname.match(link.link.url) ? styles.active : ``}`}
                >{link.link.title}</Link>
              );
              if (link.isButton) return (
                <Button key={index}>
                  <Link to={`/${link.link.url}` ?? ``}>{link.link.title}</Link>
                </Button>
              );
            })}
          </div>
        )}
        <Button
          className={`icon-btn ${styles.ham}`}
          icon={<img src={showOverlay ? CrossIcon : HamIcon} className={styles.smallIcon} />}
          handleClick={showHideOverlay}
        />
      </div>
    </header>
  )
}

export default Header

export const query = graphql`
query allContentfulHeaderLinksQuery {
  allContentfulHeaderLinks {
    nodes {
      order
      title
      isLink
      isButton
      link {
        url
        title
      }
    }
  }
}
`
